<template>
  <div class="register-container">
    <img src="/img/register.png" class="register-image" alt="Recicla" />
    <div class="register-content">
      <h2>Registro</h2>
      <Steps :step.sync="step" @update="s => (step = s)"></Steps>
        <form class="register-content-form">
        <template v-if="step === 1">
          <h3>Dados da Entidade</h3>
          <r-input
            v-model="form.name"
            label="Nome da entidade"
            :rules="rules.step1.name"
          />
          <r-input
            v-model="form.contact"
            label="Nome do contato"
            :rules="rules.step1.contact"
          />
          <r-input
            v-model="form.phone"
            label="Telefone para contato"
            mask="(##) # ####-####"
            :rules="rules.step1.phone"
          />
          <r-input
            v-model="form.cnpj"
            label="CNPJ"
            mask="##.###.###.####/##"
            :rules="rules.step1.cnpj"
          />
        </template>
        <template v-if="step === 2">
          <h3>Confirme seu endereço</h3>

          <r-input
            v-model="form.zip_code"
            label="CEP"
            mask="#####-###"
            class="col-12"
            :class="{ 'col-md-4': form.province_id && form.city_id }"
            :rules="rules.step2.zip_code"
          />
          <r-select
            label="Cidade"
            v-if="form.city_id"
            v-model="form.city_id"
            :readonly="true"
            :items.sync="cities"
            class="col-12 col-md-4"
            labelName="name"
          />
          <r-select
            label="Estado"
            v-model="form.province_id"
            v-if="form.province_id"
            :readonly="true"
            :items.sync="provinces"
            labelName="name"
            class="col-12 col-md-4"
          />
          <r-input
            v-model="form.street"
            label="Endereço"
            :rules="rules.step2.street"
            class="col-12 col-md-12"
            :readonly="loadedByZip.street"
          />
          <r-input
            v-model="form.number"
            label="Número"
            :rules="rules.step2.number"
            class="col-12 col-md-4"
            :readonly="ctrnumber"
            :type="ctrnumbertype"
          />
          <div class="col-md-4" style="padding-top: 35px">
            <input type="checkbox" id="checkbox" v-model="ctrnumber">
            &nbsp;
            <label for="checkbox">Sem número</label>
          </div>
          <r-input
            v-model="form.complement"
            class="col-md-4"
            label="Complemento"
            :rules="rules.step2.complement"
          />
          <r-input
            v-model="form.district"
            label="Bairro"
            class="col-12 col-md-12"
            :rules="rules.step2.district"
            :readonly="loadedByZip.district"
          />
        </template>
        <!-- <template v-if="step === 3">
          <h3>Insira os documentos</h3>
          <r-input v-model="form.logo_file" label="Logomarca" type="file" />
                  <input
                    @change.prevent="addLogo"
                    type="file"
                    id="image"
                    accept="image/png, image/jpeg, image/jpg"
                  />
                  <input
                    @change.prevent="addDoc"
                    type="file"
                    id="image"
                    accept="image/png, image/jpeg, image/jpg"
                  />
        </template> -->
        <template v-if="step === 3">
          <h3>Informações cadastrais</h3>
          <r-input
            v-model="form.email"
            label="Email"
            type="text"
            :rules="rules.step1.email"
          />
          <r-input
            v-model="form.password"
            label="Senha"
            type="password"
            :rules="rules.step1.password"
            info="A senha deve conter pelo menos 8 caracateres, um símbolo, uma letra e um número"
          />
          <r-input
            v-model="form.repassword"
            label="Confirmar senha"
            type="password"
            :rules="rules.step1.repassword"
          />
          <div class="col-md-12" style="padding-top: 35px">
            <input type="checkbox" id="checkbox" v-model="form.agree">
            &nbsp;
            <label for="checkbox">Ao se cadastrar, você concorda com os termos da nossa <a style="color: green" href="#">política de privacidade.</a></label>
          </div>
        </template>
      </form>
    </div>
    <Actions
      :step.sync="step"
      @update="s => (step = s)"
      @register="register"
      :loading="loading"
    />
  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert'
import registerModel from '@/models/Register'
import { mapGetters, mapActions } from 'vuex'

export default {
  data: () => ({
    step: 1,
    loading: false,
    type: 1,
    ctrnumber: false,
    ctrnumbertype: 'number',
    form: registerModel.form,
    cities: [],
    loadedByZip: {
      street: true,
      number: false,
      district: true,
      complement: false
    }
  }),
  methods: {
    ...mapActions('province', { fetchProvinces: 'fetch' }),
    async register () {
      if (this.form.email === '' || this.form.password === '' || this.form.repassword === '') {
        swal({
          title: 'Preencha todos os campos',
          text: 'Todos os campos precisam estar preenchidos',
          icon: 'error'
        })
        return
      }
      if (this.form.password !== this.form.repassword) {
        swal({
          title: 'As senhas são diferentes',
          text: 'As senhas devem ser iguais para prosseguir',
          icon: 'error'
        })
        return
      }
      this.loading = true
      try {
        const response = await axios.post('/entity', this.parseData())
        swal({
          title: 'Sucesso!',
          text: response.data.message,
          icon: 'success'
        }).then(async () => {
          registerModel.initForm()
          await this.$router.push('/auth/login')
        })
      } catch (error) {
        await swal({
          title: 'Ooops!',
          text: error.response.data.message,
          icon: 'error',
          button: 'Ok'
        })
      } finally {
        this.loading = false
      }
    },
    addLogo (e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      const vue = this
      const file = files[0]
      var reader = new FileReader()

      reader.onload = e => {
        vue.form.logo_file = e.target.result
      }
      reader.readAsDataURL(file)
    },
    addDoc (e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      const vue = this
      const file = files[0]
      var reader = new FileReader()

      reader.onload = e => {
        vue.form.cnpj_card_file = e.target.result
      }
      reader.readAsDataURL(file)
    },
    parseData () {
      const data = new FormData()

      Object.keys(this.form).map(key => {
        const item = this.form[key]
        if (Array.isArray(item)) {
          let count = 0
          item.map(subitem => {
            Object.keys(subitem).map(ik => {
              data.append(`${key}[${count}][${ik}]`, subitem[ik])
            })
            count++
          })
        } else {
          data.append(key, item)
        }
      })
      return data
    }
  },
  computed: {
    ...mapGetters('province', ['provinces']),
    rules () {
      return registerModel.rules
    }
  },
  watch: {
    async 'form.zip_code' (val) {
      if (!val || val.length < 8) return
      try {
        const response = await axios.get('/cep?cep=' + val)
        this.form = Object.assign(this.form, response.data)

        const responseCities = await axios.get('/cities?province_id=' + this.form.province_id)
        this.cities = responseCities.data

        /* const data = await this.getViaCEP({ zipCode: val })
        this.form = Object.assign(this.form, data)
        Object.keys(data).map(item => {
          this.loadedByZip[item] = !!data[item]
        }) */
      } catch (error) {
        swal({
          title: 'Falha CEP',
          text: 'Falha ao buscar os dados do CEP. Tente novamente por favor',
          icon: 'error'
        })
      }
    },
    ctrnumber (val) {
      if (val) {
        this.ctrnumbertype = 'text'
        this.form.number = 'S/N'
      } else {
        this.ctrnumbertype = 'number'
        this.form.number = null
      }
    }
  },
  mounted () {
    this.fetchProvinces()
  },
  components: {
    Steps: () => import('./components/steps.vue'),
    Actions: () => import('./components/actions.vue'),
    RInput: () => import('@/components/input.vue'),
    RSelect: () => import('@/components/select.vue')
  }
}
</script>

<style scoped>
.register-container {
  background: #ffffff !important;
  width: 1100px;
  min-height: 800px;
  max-width: 98%;
  border-radius: 0.75rem;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.15);
  padding: 1.5rem;
  margin: 2rem auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  position: relative;
}

.register-content,
.register-image {
  display: block;
}

.register-content {
  width: 62%;
}

.register-image {
  width: calc(100% - 62% - 2%);
  margin-right: 2%;
  object-fit: scale-down;
  margin-top: 4rem;
}

.register-content h2,
.register-content h3 {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 3rem;
  width: 100%;
}
.register-content h3 {
  text-align: left;
  margin-bottom: 2rem;
  color: #14a949;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.register-content-form {
  width: 100%;
  max-width: 96%;
  margin: 0 auto;
  padding: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 4rem;
}
</style>
