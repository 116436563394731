import $help from '@/plugins/helpers'

class Register {
  form = {}

  rules = {
    step1: {
      fantasy_name: [val => !!val || 'Obrigatório'],
      company_name: [val => !!val || 'Obrigatório'],
      cnpj: [
        val => !!val || 'Obrigatório',
        val => val.length < 14 ? '' : $help.cnpjValidate(val) || 'CNPJ inválido.'
      ],
      email: [
        val => !!val || 'Obrigatório',
        val => $help.emailValidate(val) || 'Digite um email válido.'
      ],
      password: [val => !!val || 'Obrigatório']
    },
    step2: {},
    step3: {},
    step4: {},
    step5: {}
  }

  constructor () {
    this.initForm()
  }

  validate (rules) {
    for (const key in this.form) {
      const rule = rules[key]
      if (!rule) continue
      const valid = rule.reduce((prev, next) => {
        if (next(this.form[key]) !== true) prev = false
        return prev
      }, true)
      if (!valid) return false
    }
    return true
  }

  initForm () {
    this.form = {
      name: '',
      contact: '',
      cnpj: '',
      email: '',
      password: '',
      province_id: '',
      city_id: '',
      zip_code: '',
      district: '',
      complement: '',
      street: '',
      number: '',
      logo_file: '',
      cnpj_card_file: '',
      agree: false
    }
  }

  get validStep1 () {
    return this.validate(this.rules.step1)
  }

  get validStep2 () {
    return this.validate(this.rules.step2)
  }

  get validStep3 () {
    return this.validate(this.rules.step3)
  }

  get validStep4 () {
    return this.validate(this.rules.step4)
  }

  get validStep5 () {
    return this.validate(this.rules.step5)
  }

  get validAll () {
    return (
      this.validStep1 &&
      this.validStep2 &&
      this.validStep3 &&
      this.validStep4 &&
      this.validStep5
    )
  }
}

export default new Register()
